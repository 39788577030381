import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { APP, ASSETS } from '../../asset';
import { Bounce, Fade, Reveal } from 'react-reveal';
import { ko } from '../../language/language';
import { useRecoilValue } from 'recoil';
import { appName, translatedText } from '../../recoil/recoil';
import MainText from '../MainText';

function WorkProcess(props) {
    const textData = useRecoilValue(translatedText);
    const usingName = useRecoilValue(appName);

    return (
        <React.Fragment>
            <section
                id="process"
                className={`work-process-section position-relative ${props.removeTop && props.removeTop === true ? 'pb-100' : 'ptb-100'
                    } ${props.isGray && props.isGray === true ? 'gray-light-bg' : ''}`}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-8">
                            <Fade>
                                <div className="section-heading text-center mb-5">
                                    {/* <h2><span className="text-warning">{APP.name}</span>{ko['23']()}</h2> */}
                                    <MainText type={'work_process'} />
                                    <p>{textData['24'](usingName[0])}</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-md-center justify-content-sm-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="work-process-wrap">
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape">
                                        <div className="d-flex align-items-center">
                                            <Fade>
                                                <div className="process-icon mr-4">
                                                    <i className="fas fa-wallet color-primary"></i>
                                                    {/* <img src={ASSETS.my_wallet} /> */}
                                                </div>
                                            </Fade>
                                            <Bounce>
                                                <div className="process-content text-left">
                                                    <h5>{textData['25']()}</h5>
                                                    <p>
                                                        {/* 회원가입 과정에서 자동으로 생성된 암호화폐 지갑을 통해
                                                        자산/거래내역 확인, 자산 내보내기, 나의 지갑 주소 내보내기 등의
                                                        기능을 지원합니다. */}
                                                        {textData['26']()}
                                                    </p>
                                                </div>
                                            </Bounce>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path
                                                className="dashed1"
                                                fill="none"
                                                stroke="rgb(95, 93, 93)"
                                                strokeWidth="1"
                                                strokeDasharray="1300"
                                                strokeDashoffset="0"
                                                d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                                            ></path>
                                            <path
                                                className="dashed2"
                                                fill="none"
                                                stroke="#ffffff"
                                                strokeWidth="2"
                                                strokeDasharray="6"
                                                strokeDashoffset="1300"
                                                d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item right-shape">
                                        <div className="d-flex align-items-center">
                                            <Fade>
                                                <div className="process-icon ml-4">
                                                    <i className="fas fa-puzzle-piece color-primary"></i>
                                                </div>
                                            </Fade>
                                            <Bounce>
                                                <div className="process-content text-right">
                                                    <h5>
                                                        {/* 토큰 판매 / 등록 */}
                                                        {textData['27']()}
                                                    </h5>
                                                    <p>
                                                        {/* USDT, BNB, WETH 등 유저들이 보유한 토큰을 등록하여
                                                        달러, 원화 등 화폐 종류에 구애받지 않고 판매가 가능합니다. */}
                                                        {textData['28']()}
                                                    </p>
                                                </div>
                                            </Bounce>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path
                                                className="dashed1"
                                                fill="none"
                                                stroke="rgb(95, 93, 93)"
                                                strokeWidth="1"
                                                strokeDasharray="1300"
                                                strokeDashoffset="0"
                                                d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                                            ></path>
                                            <path
                                                className="dashed2"
                                                fill="none"
                                                stroke="#ffffff"
                                                strokeWidth="2"
                                                strokeDasharray="6"
                                                strokeDashoffset="1300"
                                                d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape mb-0">
                                        <div className="d-flex align-items-center">
                                            <Fade>
                                                <div className="process-icon mr-4">
                                                    <i className="fas fa-truck color-primary"></i>
                                                </div>
                                            </Fade>
                                            <Bounce>
                                                <div className="process-content text-left">
                                                    <h5>{textData['29']()}</h5>
                                                    <p>
                                                        {textData['30']()}
                                                    </p>
                                                </div>
                                            </Bounce>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <Fade right>
                                <div className="img-wrap">
                                    <img src={ASSETS.main_mockup1} alt="features" className="img-fluid" />
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default WorkProcess;
