import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";
import _data from "../../data";
import { APP, ASSETS } from "../../asset";
import { Fade, Zoom } from "react-reveal";
import Typist from "react-typist";
import { Modal } from "bootstrap";
import ReactModal from "react-modal";
import { ko } from "../../language/language";
import { useRecoilValue } from "recoil";
import { appName, language, translatedText } from "../../recoil/recoil";

const HeroSection = (props) => {
  const targetDate = new Date("2025-01-31T00:00:00+09:00");
  const now = new Date();
  const difference = targetDate - now;


  const [hero, setHero] = useState({});
  const [mounted, setMounted] = useState(false);
  const [email, setEmail] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnText, setBtnText] = useState("Subscribe");
  const [modalVisible, setModalVisible] = useState(false);
  const lang = useRecoilValue(language)
  const usingName = useRecoilValue(appName);
  const textData = useRecoilValue(translatedText);

  const calculateTimeDifference = (diff) => {
    return {
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((diff % (1000 * 60)) / 1000)
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeDifference(difference));

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft(calculateTimeDifference(difference));
        console.log("Current timeLeft:", timeLeft);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const changeBtnText = (newBtnText) => {
    setBtnText(newBtnText);
  };

  const handleFormValueChange = (inputName, event) => {
    const stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox" ? event.target.checked : event.target.value;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // disable the button
    setDisableBtn(true);

    // get action
    const subscribeAction = subscribe({ ...props.state, email });

    // Dispatch the contact from data
    props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(() => {
      // enable the button
      setDisableBtn(false);

      // change to button name
      changeBtnText("Subscribe");

      // get action again to update state
      const subscribeAction = subscribe({ ...props.state, email });

      // Dispatch the contact from data
      props.dispatch(subscribeAction);

      // clear form data
      setEmail("");
    }, 3000);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      zIndex: '999',
      transform: 'translate(-50%, -50%)',
      borderRadius: '20px',
      width: "90%"
    },
    overlay: { zIndex: 1000 }
  };

  return (
    <React.Fragment>
      <style>
        {`
          @keyframes rotate {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          .progress-circle {
            stroke-dasharray: 440;
            stroke-dashoffset: 440;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
          }
          .dotted-circle {
            stroke-dasharray: 3, 8;
            stroke-linecap: round;
          }
        `}
      </style>
      <ReactModal
        isOpen={modalVisible}
        style={customStyles}
        onRequestClose={() => setModalVisible(false)}
      >
        {/* <div className="modal_video_container"> */}
        <video src={ASSETS.about_video} autoPlay loop style={{ width: "100%", height: "100%" }} controls />
        {/* </div> */}
      </ReactModal>
      <section className="ptb-100 bg-image overflow-hidden" image-overlay="10">
        <div
          className="hero-bottom-shape-two"
          style={{
            backgroundImage: "url(assets/img/hero-bottom-shape-2.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom center",
          }}
        >
          <div className="animated-shape-wrap">
            <div className="animated-shape-item"></div>
            <div className="animated-shape-item"></div>
            <div className="animated-shape-item"></div>
            <div className="animated-shape-item"></div>
            <div className="animated-shape-item"></div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
            <div className="col-md-12 col-lg-6">
              <div className="hero-slider-content text-white py-5">
                <Typist cursor={{ blink: false }} key={lang} className="">
                  <h2 className="text-white" style={{ display: "inline-block" }}>
                    {textData['1']()}
                  </h2>
                  <h1 className="text-warning h1">{usingName[0]}</h1>
                </Typist>
                <Zoom>
                  <p className="lead">
                    {/* {APP.full_name}은 기존의 거래소를 통한 암호화폐 현금화, 이종토큰화
                    방식을 유저간 거래를 통해 간편하게, 스마트 컨트랙트를 통해서
                    안전하게 거래하도록 전환시킨 암호화폐 개인거래 플랫폼입니다. */}
                    {textData["2"](usingName[1])}
                  </p>
                  {/* <div className="action-btns mt-3">
                    <a href="/#" className="btn btn-brand-03 btn-rounded mr-3 mb-0">
                      {textData['3']()}
                      <i className="fas fa-cloud-download-alt pl-2"></i>
                    </a>
                    <button
                      className="popup-youtube btn btn-white btn-circle btn-icon"
                      onClick={() => setModalVisible(true)}
                    >
                      <i className="fas fa-play"></i>{" "}
                    </button>{" "}
                    <span className="pl-2">
                      {textData['4']()}
                    </span>
                  </div> */}
                </Zoom>
                <div className="tbx-counter mt-5">
                  <div className="row">
                    <div className="col-6 col-sm-3">
                      <div className="position-relative d-flex align-items-center justify-content-center" style={{ width: "140px", height: "140px", margin: "0 auto" }}>
                        <svg className="position-absolute w-100 h-100" viewBox="0 0 160 160">
                          <circle
                            className="dotted-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.5)"
                            strokeWidth="2"
                          />
                          <circle
                            className="progress-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.8)"
                            strokeWidth="2"
                            style={{
                              strokeDashoffset: `${440 - (440 * (timeLeft.days / 5))}`,
                              transition: 'stroke-dashoffset 0.5s ease'
                            }}
                          />
                        </svg>
                        <div className="position-relative d-flex flex-column align-items-center justify-content-center rounded-circle border border-2 z-1"
                          style={{
                            width: "calc(100% - 16px)",
                            height: "calc(100% - 16px)",
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderColor: "rgba(255, 255, 255, 0.2)"
                          }}>
                          <span className="h4 counter-number text-white mb-0" style={{ fontSize: "2.5rem" }}>
                            {String(timeLeft.days).padStart(2, '0')}
                          </span>
                          <h6 className="text-white text-uppercase mb-0" style={{ letterSpacing: "1px", fontSize: "0.875rem" }}>Days</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="position-relative d-flex align-items-center justify-content-center" style={{ width: "140px", height: "140px", margin: "0 auto" }}>
                        <svg className="position-absolute w-100 h-100" viewBox="0 0 160 160">
                          <circle
                            className="dotted-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.5)"
                            strokeWidth="2"
                          />
                          <circle
                            className="progress-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.8)"
                            strokeWidth="2"
                            style={{
                              strokeDashoffset: `${440 - (440 * (timeLeft.hours / 24))}`,
                              transition: 'stroke-dashoffset 0.5s ease'
                            }}
                          />
                        </svg>
                        <div className="position-relative d-flex flex-column align-items-center justify-content-center rounded-circle border border-2 z-1"
                          style={{
                            width: "calc(100% - 16px)",
                            height: "calc(100% - 16px)",
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderColor: "rgba(255, 255, 255, 0.2)"
                          }}>
                          <span className="h4 counter-number text-white mb-0" style={{ fontSize: "2.5rem" }}>
                            {String(timeLeft.hours).padStart(2, '0')}
                          </span>
                          <h6 className="text-white text-uppercase mb-0" style={{ letterSpacing: "1px", fontSize: "0.875rem" }}>Hours</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="position-relative d-flex align-items-center justify-content-center" style={{ width: "140px", height: "140px", margin: "0 auto" }}>
                        <svg className="position-absolute w-100 h-100" viewBox="0 0 160 160">
                          <circle
                            className="dotted-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.5)"
                            strokeWidth="2"
                          />
                          <circle
                            className="progress-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.8)"
                            strokeWidth="2"
                            style={{
                              strokeDashoffset: `${440 - (440 * (timeLeft.minutes / 60))}`,
                              transition: 'stroke-dashoffset 0.5s ease'
                            }}
                          />
                        </svg>
                        <div className="position-relative d-flex flex-column align-items-center justify-content-center rounded-circle border border-2 z-1"
                          style={{
                            width: "calc(100% - 16px)",
                            height: "calc(100% - 16px)",
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderColor: "rgba(255, 255, 255, 0.2)"
                          }}>
                          <span className="h4 counter-number text-white mb-0" style={{ fontSize: "2.5rem" }}>
                            {String(timeLeft.minutes).padStart(2, '0')}
                          </span>
                          <h6 className="text-white text-uppercase mb-0" style={{ letterSpacing: "1px", fontSize: "0.875rem" }}>Minutes</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="position-relative d-flex align-items-center justify-content-center" style={{ width: "140px", height: "140px", margin: "0 auto" }}>
                        <svg className="position-absolute w-100 h-100" viewBox="0 0 160 160">
                          <circle
                            className="dotted-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.5)"
                            strokeWidth="2"
                          />
                          <circle
                            className="progress-circle"
                            cx="80"
                            cy="80"
                            r="70"
                            fill="none"
                            stroke="rgba(255,255,255,0.8)"
                            strokeWidth="2"
                            style={{
                              strokeDashoffset: `${440 - (440 * (timeLeft.seconds / 60))}`,
                              transition: 'stroke-dashoffset 0.5s ease'
                            }}
                          />
                        </svg>
                        <div className="position-relative d-flex flex-column align-items-center justify-content-center rounded-circle border border-2 z-1"
                          style={{
                            width: "calc(100% - 16px)",
                            height: "calc(100% - 16px)",
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderColor: "rgba(255, 255, 255, 0.2)"
                          }}>
                          <span className="h4 counter-number text-white mb-0" style={{ fontSize: "2.5rem" }}>
                            {String(timeLeft.seconds).padStart(2, '0')}
                          </span>
                          <h6 className="text-white text-uppercase mb-0" style={{ letterSpacing: "1px", fontSize: "0.875rem" }}>Seconds</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-5">
              <Fade left>
                <div className="img-wrap">
                  <img src={ASSETS.mockup_main} alt="app" className="img-thumbnail" />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HeroSection